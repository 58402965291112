import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Redirect } from '@reach/router'
import { isEmpty } from 'lodash'

import base from '../sagas/base'
import Article from '../components/article/article'
import LatestNewsArticle from '../components/LatestNewsArticle'
import Category from '../components/Category'
import Layout from '../components/layout'
import SEO from '../components/seo'
import transformArticle from '../utils/transform-article-data'
import { fetchSubArticles } from '../utils/liveArticleHelper'
import { getCategoryPage } from '../utils/sendEvent'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const [article, setArticle] = useState(null)
  const [latestNews, setLatestNews] = useState(null)
  const [category, setCategory] = useState(null)
  const [homePageRedirect, setHomePageRedirect] = useState(false)

  useEffect(() => {
    let match = location.pathname.match('^/(ar|en)/([A-Za-z0-9]+)article?/?$')

    if (!match) {
      match = location.pathname.match('^/(ar|en)/([A-Za-z0-9]+)-')
    }

    if (match) {
      const id = match[2]
      const lang = match[1]

      base
        .get('/content', { id })
        .then(async (response) => {
          const { success, data } = response.data
          const articleType = success && data ? data.article_type : null

          if (articleType !== 'sub-live') {
            if (articleType === 'live') {
              const subLiveIds = data.subLiveIds || []
              if (!isEmpty(subLiveIds)) {
                try {
                  const liveArticleData = transformArticle({
                    article: data,
                    lang
                  })
                  const transformedSubLiveArticleList = await fetchSubArticles(
                    subLiveIds,
                    lang
                  )
                  liveArticleData.subArticles = transformedSubLiveArticleList
                  // to avoid retching all the sub-lives again in article component
                  liveArticleData.shouldRefetchData = false
                  setArticle(liveArticleData)
                } catch (err) {
                  console.log('error in fetching sub-live article content', err)
                  setArticle(false)
                }
              } else {
                setArticle(false)
              }
            } else {
              setArticle(transformArticle({ article: data, lang }))
            }
          } else {
            setArticle(false)
          }
        })
        .catch((e) => {
          setArticle(false)
        })
    } else {
      match = location.pathname.match('^/(ar|en)')
      // redirect to home page if the route is /ar/ or /ar
      if (match) {
        setHomePageRedirect(true)
      }
      setArticle(false)
    }
  }, [])

  useEffect(() => {
    const match = location.pathname.match('^/latest-news/([A-Za-z0-9]+)/')

    if (match) {
      const id = match[1]

      base
        .get('/content', { id })
        .then((response) => {
          const { success, data } = response.data

          if (success) {
            const { item_id, description_ar, title_ar, ...rest } = data

            setLatestNews({
              ...rest,
              id: item_id,
              body: description_ar,
              title: title_ar
            })
          } else {
            setLatestNews(false)
          }
        })
        .catch((e) => {
          setLatestNews(false)
        })
    } else {
      setLatestNews(false)
    }
  }, [])

  useEffect(() => {
    const isCat = getCategoryPage()
    const match = location.pathname.match('^/category/([A-Za-z0-9]+)/')

    if (isCat || match) {
      const id = isCat ? window.currCategory : match[1]

      base.get('categories', { size: 100 }).then((categoriesList) => {
        if (categoriesList && categoriesList.data && categoriesList.data.data) {
          const filteredCat = categoriesList.data.data.find(
            (item) =>
              item.name.en === id || item.name.ar === id || item.id === id
          )

          setCategory(filteredCat)
        }
      })
    } else {
      setCategory(false)
    }
  }, [])

  if (article === null || latestNews === null || category === null) {
    return null
  }

  const renderCategories = () => {
    const lang = 'ar'

    return (
      <Layout maxWidth="auto" location={location} lang={lang}>
        <Category category={category} data={[]} lang={lang} />
      </Layout>
    )
  }

  const renderNotFound = () => {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <h1>المقالة غير موجودة</h1>
        <p>عذرًا، لم نتمكن من العثور على الصفحة التي تبحث عنها.</p>
      </Layout>
    )
  }

  const renderArticle = () => {
    return (
      <Layout location={location} title={''} lang={article.lang}>
        <Article article={article} location={location} grid={1} />
      </Layout>
    )
  }

  const renderLatestNews = () => {
    const lang = 'ar'

    return (
      <Layout maxWidth="auto" location={location} lang={lang}>
        <LatestNewsArticle article={latestNews} lang={lang} />
      </Layout>
    )
  }

  if (article) {
    return renderArticle()
  }

  if (latestNews) {
    return renderLatestNews()
  }

  if (category) {
    return renderCategories()
  }

  if (homePageRedirect) {
    return <Redirect noThrow to={`/`} />
  }

  return renderNotFound()
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
