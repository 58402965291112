import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const Container = styled.div`
  margin: 15px -10px 0 -10px;
`

export const Title = styled.h1`
  margin: 10px 0 15px 0;
  font-size: 28px;
  font-weight: bold;
  max-width: 75%;
  color: #4f4f50;
  line-height: 30px;
`

export const PaddingContainer = styled.div`
  padding: 0 20px;
  display: grid;
`
export const FooterContainer = styled.div`
  padding: 0 10px 10px 10px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  display: grid;
`

export const Body = styled.p`
  margin: 30px 0 30px 0;
  color: #7d7d83;
  font-size: 16px;
  line-height: 24px;
`

export const Hr = styled.div`
  height: 1px;
  background-color: #ececec;
`

export const Date = styled.div`
  color: #7d7d82;
  font-size: 15px;
`

export const SocialContainer = styled.div`
  margin: 15px 0 20px 0;
`

export const Article = styled.div`
  @media ${device.m} {
    padding: 20px 30px;
  }

  @media ${device.l} {
    padding-top: 20px;
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 260px;
    grid-gap: 10px;
  }

  @media ${device.xl} {
    padding-top: 20px;
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 260px;
    grid-gap: 40px;
  }
`

export const LatestNewsDesktopContainer = styled.div`
  border: 1px solid #ececec;
  border-radius: 0 10px 0 10px;
  height: 370px;
  margin: 0 20px;
  background-color: #fff;
`

export const PageTitle = styled.div`
  color: #c71b1c;
  font-size: 18px;
  font-weight: bold;

  @media ${device.s} {
    margin-top: 25px;
  }
`
