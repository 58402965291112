import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import loadable from '@loadable/component'
import { connect } from 'react-redux'
import DateTimeService from '../../utils/common'

import {
  Container,
  Title,
  PaddingContainer,
  Body,
  Hr,
  Date,
  SocialContainer,
  Article,
  LatestNewsDesktopContainer,
  FooterContainer
} from './styles'
import { breakpoints } from '../../constants/app'
import LatestNews from '../LatestNews'
import SEO from '../seo'
import SocialControls from '../socialcontrols/socialcontrols'
import useViewportSize from '../../utils/useViewportSize'
import MobileLiveTv from '../livetv/mobileLiveTv'
import { addGoogleAnalyticsPageData } from '../../utils/googleAnalyticsEvents'

const Footer = loadable(() => import('../footer/footer'))

const LatestNewsArticle = ({ article, quickLinks, hideLiveTvMobile }) => {
  const { width: screenWidth } = useViewportSize()

  const imageUrl =
    'https://img-staging.asharq.com/30931574-cdd2-4bed-85e6-9d6b8b8d8933.png?w=1200&h=628&dpr=2&ixlib=js-2.3.1&s=c32ee3f1b87404fb962d16d15c713712'

  const articleUrl = `${process.env.GATSBY_BASE_URL}latest-news/${article.id}/`

  useEffect(() => {
    try {
      const articleDate =
        article.publishedAt || article.updatedAt || article.createdAt
      let articleYear = ''
      const parsedData = DateTimeService.parseISOIfValid(articleDate)

      if (parsedData && parsedData.getFullYear) {
        articleYear = parsedData.getFullYear() || ''
      }

      const articleData = {
        content_id: `${article.id}`,
        content_type: article.type || 'headline',
        content_year: articleYear,
        content_length: article.body ? article.body.length : 0,
        content_language: article.lang || 'ar',
        content_audience: 'regular',
        content_premium: 'no',
        publish_date: DateTimeService.formatInLocalTime(
          articleDate,
          'yyyy-MM-dd'
        ),
        analyticsEventLabel: 'headline',
        content_title: article.title
      }

      addGoogleAnalyticsPageData(articleData)
    } catch (error) {
      console.log('Error in LatestNewsArticle useEffect', error)
    }
  }, [])

  const renderArticle = () => {
    const articleTitle = `${article.title} | الشرق للأخبار`
    const articleDescription = `${article.seoDescription} الشرق للأخبار`

    return (
      <>
        <SEO title={articleTitle} description={articleDescription} lang="ar" />
        <Helmet>
          <meta property="og:image" itemProp="image" content={imageUrl} />
          <meta property="og:image:url" itemProp="image" content={imageUrl} />
          <meta
            property="og:image:secure_url"
            itemProp="image"
            content={imageUrl}
          />
          <meta
            property="og:description"
            content={article.body.split('\n')[0]}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
          <meta property="og:image:alt" content={articleTitle} />
          <meta property="og:image:type" content="image/png" />

          <meta property="fb:app_id" content="236955207547425" />
          <meta property="og:url" content={articleUrl} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:image" content={imageUrl} />
          <link rel="canonical" href={articleUrl} />
        </Helmet>

        {quickLinks.showLiveTvMobileInGrid && (
          <div>
            <MobileLiveTv
              hideLiveTvMobile={hideLiveTvMobile}
              isAsharqAppPopupVisible={quickLinks.isAsharqAppPopupVisible}
            />
          </div>
        )}

        <PaddingContainer>
          <Title>{article.title}</Title>

          <Date>
            <time
              dateTime={DateTimeService.formattedDateTime(
                article.publishedAt || article.updatedAt || article.createdAt
              )}
            >
              {DateTimeService.formatInLocalTime(
                article.publishedAt || article.updatedAt || article.createdAt
              )}
            </time>
          </Date>

          <SocialContainer>
            <SocialControls
              visible={true}
              theme="lightgrey"
              themehover="midgrey"
              article={article}
              itemType="headline"
              page={'articlepage'}
              hideOnMobile={false}
            />
          </SocialContainer>
        </PaddingContainer>

        <Hr />

        <PaddingContainer>
          <Body
            dangerouslySetInnerHTML={{
              __html: article.body.replace(/\n/g, '<br />')
            }}
          />
        </PaddingContainer>
      </>
    )
  }

  return (
    <Container id="latest_news">
      <div style={{ marginTop: -7 }}>
        {screenWidth <= breakpoints.m && <LatestNews type="mobile" />}
      </div>

      <Article>
        <div>{article.id ? renderArticle() : null}</div>

        {screenWidth > breakpoints.m && (
          <LatestNewsDesktopContainer>
            <LatestNews scrollable poll />
          </LatestNewsDesktopContainer>
        )}
      </Article>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideLiveTvMobile: () => {
      dispatch({ type: 'SHOW_HIDE_LIVE_TV_MOBILE', payload: false })
    }
  }
}

const mapStateToProps = ({ quickLinks }) => {
  return {
    quickLinks
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LatestNewsArticle)
